<div class="overlay"></div>
<div class="card mx-auto">
  <h3 class="card-header">Connexion</h3>
  <div class="card-block">
    <h6 class="card-title">Connectez vous pour continuer</h6>
    <form (keydown)="keyDownFunction($event)">
      <div class="form-group">
        <label>Adresse email</label>
        <input type="email" class="form-control" placeholder="Entrez votre adresse mail" [(ngModel)]="admin.email" name="email">
      </div>
      <div class="form-group">
        <label>Mot de passe</label>
        <input type="password" class="form-control" placeholder="Entrez votre mot de passe" [(ngModel)]="admin.password" name="password">
      </div>
      <small class="form-text text-danger">{{error}}</small>
      <button type="button" class="btn btn-primary btn-block mt-4 pointer" (click)="loginAdmin()">Connexion</button>
    </form>
  </div>
</div>
