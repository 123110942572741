<div id="loading" *ngIf="loading">
    <i class="fas fa-cog loader gly-spin"></i>
</div>
<header class="app-header navbar" *ngIf="!loading">
  <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>&#9776;</button>
  <a class="navbar-brand" href="#"></a>
  <button class="navbar-toggler d-md-down-none" type="button" appSidebarToggler>&#9776;</button>

  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item dropdown" dropdown (onToggle)="toggled($event)">
      <a href class="nav-link dropdown-toggle" dropdownToggle (click)="false">
        <img [src]="imgProfil" class="img-avatar" alt="Avatar de profil">
        <span class="d-md-down-none text-capitalize">{{admin.name}}</span>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>Accompte</strong></div>
        <a class="dropdown-item"  routerLinkActive="active" [routerLink]="['/admin/profil']"><i class="fa fa-user"></i> Profil</a>
        <button class="dropdown-item btn-transparent" (click)="logout()"><i class="fa fa-lock"></i> Déconnexion</button>
      </div>
    </li>
  </ul>
</header>

<div class="app-body" *ngIf="!loading">
  <div class="sidebar">
    <nav class="sidebar-nav">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/dashboard']"><i class="icon-speedometer"></i> Tableau de bord</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/booking']"><i class="fas fa-calendar-alt" aria-hidden="true"></i> Réservations</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/gift']"><i class="fas fa-gift" aria-hidden="true"></i> Bons cadeaux</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/reception']"><i class="fas fa-people-carry"></i> Salle de réception</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/profit']"><i class="fas fa-balance-scale" aria-hidden="true"></i> Comptes</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/users']"><i class="fa fa-user" aria-hidden="true"></i> Utilisateurs</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/message']"><i class="fa fa-envelope" aria-hidden="true"></i> Messagerie</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/stats']"><i class="fa fa-chart-pie" aria-hidden="true"></i> Statistiques</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/absences']"><i class="far fa-dizzy" aria-hidden="true"></i> Absences</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/rooms']"><i class="fas fa-door-open" aria-hidden="true"></i> Gestions des salles</a>
        </li>
      </ul>
    </nav>
  </div>

  <!-- Main content -->
  <main class="main">

    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.conainer-fluid -->
  </main>
</div>

<footer class="app-footer text-center">
  <span class="float-left"><a href="http://secretdoor.fr">Secret Door</a> &copy; 2018</span>
  <a routerLinkActive="active" [routerLink]="['/changelog']">Changelog</a>
  <span class="float-right">Développé par Aikyos</span>
</footer>
